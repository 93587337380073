<template>
    <div class="table">
        <!-- 头部 -->
        <div class="crumbs">
            <Breadcrumb v-bind:menuList="menuList"></Breadcrumb>
        </div>
        <div class="container">
            <el-form ref="form" :model="form" label-width="90px" :rules="rules" label-position="left" class="dialog_box">
                <el-form-item label="文章标题" prop="title">
                    <el-input v-model="form.title" placeholder="请输入文章标题"></el-input>
                </el-form-item>

                <!-- <el-form-item label="文章内容" prop="content">
                    <div class="quill-box" style="height: 700px">
                        <QuillEditor :detailContent.sync="form.content"></QuillEditor>
                    </div>
                </el-form-item> -->

                <el-form-item label="文章内容" prop="content">
                    <div class="quill-box" style="height: 700px">
                        <wangEditor :content.sync='form.content'></wangEditor>
                    </div>
                </el-form-item>

                <!-- 图片上传 -->
                <el-form-item label="头像" prop="avatar">
                    <el-input v-model="form.avatar" class="form-input" placeholder="请输入头像链接" style="margin-bottom: 20px"></el-input>
                    <el-button @click.prevent="choiceImg('avatar')">选择图片</el-button>
                    <el-upload :class="[form.avatar ? 'avatar-uploader1' : 'avatar-uploader', 'img1', 'up']" :action="IMAGE_UPLOAD_PRO_URL" :show-file-list="false" :on-success="handleAvatarSuccess" :before-upload="beforeAvatarUpload" name="file" :headers="header">
                        <img v-if="form.avatar" :src="form.avatar" class="logo" />
                        <i v-else class="el-icon-plus logo-uploader-icon"></i>
                    </el-upload>
                </el-form-item>
                <el-form-item label="封面图" prop="thumb">
                    <el-input class="form-input" v-model="form.thumb" placeholder="请输入封面图链接" style="margin-bottom: 20px"></el-input>
                    <el-button @click.prevent="choiceImg('thumb')">选择图片</el-button>
                    <el-upload :class="[form.thumb ? 'avatar-uploader1' : 'avatar-uploader', 'img2', 'up']" :action="IMAGE_UPLOAD_PRO_URL" :show-file-list="false" :on-success="handleThumbSuccess" :before-upload="beforeThumbUpload" name="file" :headers="header">
                        <img v-if="form.thumb" :src="form.thumb" class="avatar" />
                        <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                    </el-upload>
                </el-form-item>
                <el-form-item label="用户id" prop="uid">
                    <el-input v-model="form.uid" placeholder="请输入用户id"></el-input>
                </el-form-item>
                <el-form-item label="用户名" prop="username">
                    <el-input v-model="form.username" placeholder="请输入用户名"></el-input>
                </el-form-item>
                <el-form-item label="来源id" prop="source_id">
                    <el-input v-model="form.source_id" placeholder="请输入来源id"></el-input>
                </el-form-item>
                <el-form-item label="来源" prop="ty">
                    <el-radio-group v-model="form.ty">
                        <el-radio :label="1">抖音</el-radio>
                    </el-radio-group>
                </el-form-item>
                <el-form-item label="来源内容" prop="source_cntent">
                    <el-input v-model="form.source_cntent" placeholder="请输入来源内容"></el-input>
                </el-form-item>
                <el-form-item prop="tag" label="标签">
                    <el-tag v-for="(item, index) in tagList" :key="item.index" closable :disable-transitions="true" @close="handleClose(index)">{{ item.name }}</el-tag>
                    <el-autocomplete ref="saveTagInput" v-if="tagVisible" class="inline-input" v-model="tag" :fetch-suggestions="queryTagSearch" placeholder="输入内容" :trigger-on-focus="false" @select="handleTagSelect"></el-autocomplete>
                    <el-button v-else class="button-new-tag" size="small" @click="showtag">新增标签</el-button>
                </el-form-item>
                <el-form-item label="关联栏目" prop="article_column_id">
                    <el-select v-model="form.article_column_id" placeholder="请选择关联栏目" class="handle-select mr10">
                        <el-option v-for="items in columnList" :key="items.index" :label="items.name" :value="items.id"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="是否加精" prop="is_tperfect">
                    <el-radio-group v-model="form.is_tperfect">
                        <el-radio :label="2">是</el-radio>
                        <el-radio :label="1">否</el-radio>
                    </el-radio-group>
                </el-form-item>
                <el-form-item label="是否置顶" prop="is_top">
                    <el-radio-group v-model="form.is_top">
                        <el-radio :label="2">是</el-radio>
                        <el-radio :label="1">否</el-radio>
                    </el-radio-group>
                </el-form-item>
                <el-form-item label="状态" prop="state">
                    <el-radio-group v-model="form.state">
                        <el-radio :label="2">上线</el-radio>
                        <el-radio :label="1">下线</el-radio>
                    </el-radio-group>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="cancel">取 消</el-button>
                <el-button type="primary" @click="saveData">确 定</el-button>
            </span>
        </div>
        <!-- 选择图片对话框 -->
        <div class="img">
            <Picture :ImgVisible="ImgVisible" @toggleImg="toggleImg" @getImg="getImg"></Picture>
        </div>
    </div>
</template>
<script>
import Breadcrumb from '@/components/Breadcrumb';
import Pagination from '@/components/Pagination';
import loadEvents from '@/utils/loading';
import { request } from '@/common/request';
import Picture from '@/components/Picture';
import 'quill/dist/quill.core.css';
import 'quill/dist/quill.snow.css';
import 'quill/dist/quill.bubble.css';
import { quillEditor } from 'vue-quill-editor';
import bus from '@/common/bus';
import { deal } from '@/common/main';
import { IMAGE_UPLOAD_PRO_URL } from '../../common/const';
import QuillEditor from '@/components/weappQuillEditor';
import wangEditor from '@/components/wangEditor/index.vue';
let loads = new loadEvents();
export default {
    name: 'seller',
    components: {
        Breadcrumb,
        Pagination,
        quillEditor,
        Picture,
        QuillEditor,
        wangEditor
    },
    data() {
        return {
            IMAGE_UPLOAD_PRO_URL: IMAGE_UPLOAD_PRO_URL,
            ImgVisible: false,
            text: 'image',
            tagVisible: false,
            tag: '',
            columnList: [],
            tagList: [],
            header: {},
            editorOption: {
                placeholder: '请输入文章内容'
            },
            menuList: ['社区', '文章新增'],
            queryParams: {
                page: 1,
                pagesize: -1
            },
            //验证规则
            rules: {
                title: [
                    {
                        required: true,
                        message: '文章标题不能为空',
                        trigger: 'blur'
                    }
                ],
                content: [
                    {
                        required: true,
                        message: '文章内容不能为空',
                        trigger: 'blur'
                    }
                ],
                state: [
                    {
                        required: true,
                        message: '状态不能为空',
                        trigger: 'blur'
                    }
                ],
                is_tperfect: [
                    {
                        required: true,
                        message: '是否加精不能为空',
                        trigger: 'blur'
                    }
                ],
                is_top: [
                    {
                        required: true,
                        message: '是否置顶不能为空',
                        trigger: 'blur'
                    }
                ],
                source_id: [
                    {
                        trigger: 'blur'
                    },
                    {
                        pattern: '^[0-9]*$',
                        message: '只能输入数字'
                    }
                ],
                uid: [
                    {
                        trigger: 'blur'
                    },
                    {
                        pattern: '^[0-9]*$',
                        message: '只能输入数字'
                    }
                ],
                avatar: [{ validator: deal.checkImage, trigger: ['blur', 'change'], required: true }],
                thumb: [{ validator: deal.checkImage, trigger: ['blur', 'change'], required: true }]
            },
            loading: false,
            title: '加载中',
            form: {
                state: 2
            }
        };
    },
    created() {
        this.header.token = localStorage.getItem('token');
        this.header.platform = 'admin';
        this.loadColumnList();
    },

    //图片上传
    methods: {
        // 获取栏目
        loadColumnList() {
            let _this = this;
            request.get('/community/article/column/list', { state: 2, page: 1, pagesize: -1 }).then((ret) => {
                if (ret.code == 1) {
                    this.columnList = ret.data.list;
                } else {
                    this.$message.error(ret.msg);
                }
            });
        },
        // logo上传
        handleAvatarSuccess(res, file) {
            if (res.code == 1) {
                loads.close();
                this.$set(this.form, 'avatar', res.data.uri);
            }
        },
        beforeAvatarUpload(file) {
            loads.open(`.img1 .el-upload`, '上传中');
            const _this = this;
            return deal.imageFormat(file, _this);
        },
        // 海报上传
        handleThumbSuccess(res, file) {
            if (res.code == 1) {
                loads.close();
                this.$set(this.form, 'thumb', res.data.uri);
            }
        },
        beforeThumbUpload(file) {
            loads.open(`.img2 .el-upload`, '上传中');
            const _this = this;
            return deal.imageFormat(file, _this);
        },
        //增加编辑
        saveData() {
            if (this.$refs.form) {
                this.$refs.form.validate((valid) => {
                    if (valid) {
                        let tagId = this.tagList.map((item) => item.id);
                        let tagIds = tagId.join(',');
                        this.$set(this.form, 'tag_ids', tagIds);
                        var url = '/community/article/add';
                        request.post(url, this.form).then((ret) => {
                            if (ret.code == 1) {
                                this.addVisible = false;
                                this.$message.success('新增成功');
                                bus.$emit('close_current_tags', '/activity-PlaceAdd');
                                this.$router.push({ path: '/community-Lettres' });
                                this.$refs.form.clearValidate();
                            } else {
                                this.$message.error(ret.msg);
                            }
                        });
                    } else {
                        this.$message.error('请补全信息');
                        return false;
                    }
                });
            }
        },
        // 关闭tag
        handleClose(index) {
            this.tagList.splice(index, 1);
            this.state = '';
        },
        showtag() {
            this.tagVisible = true;
        },
        queryTagSearch(queryString, cb) {
            let results = [];
            this.queryParams.name = queryString;
            request.get('/common/tag/list', this.queryParams).then((ret) => {
                if (ret.code == 1) {
                    if (ret.data.list.length > 0) {
                        setTimeout(() => {
                            for (let i of ret.data.list) {
                                i.value = i.name; //将想要展示的数据作为value
                            }
                            results = ret.data.list;
                            cb(results);
                        }, 200);
                    } else {
                        request.post('/common/tag/add', { name: queryString, state: 2 }).then((ret) => {
                            if (ret.code == 1) {
                                this.queryTagSearch(queryString, cb);
                            } else {
                                this.$message.error(ret.msg);
                            }
                        });
                    }
                } else {
                    this.$message.error(ret.msg);
                }
            });
        },
        // 
        // 新增tag
        handleTagSelect(item) {
            this.tagVisible = false;
            this.tagList.push(item);
            let obj = {};
            let newArr = this.tagList.reduce((cur, next) => {
                obj[next.id] ? '' : (obj[next.id] = true && cur.push(next));
                return cur;
            }, []);
            this.tagList = newArr;
            this.tag = null;
        },
        toggleImg(val) {
            // 关闭 img对话框
            this.ImgVisible = val;
        },
        choiceImg(text) {
            this.text = text;
            this.ImgVisible = true;
        },
        getImg(url) {
            // 获取选择的img
            this.$set(this.form, this.text, url);
        },
        // 取消按钮
        cancel() {
            bus.$emit('close_current_tags', '/community-Lettres');
            this.$router.push({ path: '/Session' });
        }
    }
};
</script>

<style scoped>
.span_gray {
    font-size: 12px;
    color: gray;
}

.dialog_box .el-form-item--small.el-form-item {
    margin-bottom: 20px;
}

.dialog_box .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 150px;
    height: 200px;
    line-height: 200px;
    text-align: center;
}
.dialog_box .logo-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 100px;
    height: 100px;
    line-height: 100px;
    text-align: center;
}

.dialog_box .avatar {
    font-size: 28px;
    width: 150px;
    height: 200px;
    line-height: 200px;
}
.dialog_box .logo {
    font-size: 28px;
    width: 100px;
    height: 100px;
    line-height: 100px;
}
.dialog_box .up {
    margin-top: 10px;
    margin-bottom: -10px;
}

.dialog_box .el-button {
    margin-left: 20px;
}
.dialog_box {
    padding: 30px;
}
.span_gray {
    font-size: 12px;
    color: gray;
}
.dialog_box >>> .el-select .el-input--large .el-input__inner {
    width: 400px;
}
.dialog_box >>> .el-input--large .el-input__inner {
    width: 201%;
}
.dialog_box >>> .el-dialog__body {
    padding: 20px 30px 20px 60px;
}
.dialog_box >>> .el-dialog__header {
    padding: 30px 30px 10px;
}
.dialog_box .el-form-item--small .el-form-item {
    margin-bottom: 20px;
}

.dialog_box .up {
    margin-top: 20px;
}

.button-new-tag {
    margin-left: 10px;
    height: 32px;
    line-height: 30px;
    padding-top: 0;
    padding-bottom: 0;
}

.input-new-tag {
    width: 90px;
    margin-left: 10px;
    vertical-align: bottom;
}

.container {
    background: #ffffff;
}

.dialog_box >>> .el-input--small .el-input__inner {
    width: 500px;
}
/* 文本编辑样式 */
.editor {
    width: 60%;
    height: 600px;
}

.editor >>> .ql-container {
    height: 85%;
}
/* 选择链接样式 */
.dialog_box .el-button {
    margin-left: 20px;
}
</style>
